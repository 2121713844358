(function () {
        angular.module('informaApp')
            .component('durationViewElement', {
                templateUrl: 'components/summary-view/summary-elements/elements/gantt-chart-element/duration-view-element/template.html',
                controller: DurationViewElement,
                bindings: {
                    data: '<',
                    settings: '<',
                    filter: '<'
                }
            });

        function DurationViewElement(GanttChartMapper) {
            this.chartOptions = {
                removeOddTicks: true
            };

            this.$onChanges = () => {
                this.chartSource = GanttChartMapper.mapAllForHorizontalbar(this.data);
            }
        }
    }
)();